import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { ArrowLeft } from 'components/icons';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { endOfMonth } from 'date-fns/endOfMonth';
import useQueryParam from 'hooks/useQueryParam';
import { useState } from 'react';
import { FilingHistoryFiltersType } from 'types/filing';
import { FilingStatus } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';

import FilingsHistoryTable from './filings-history';
import { FilterButton } from './filter-button';

const FilingHistoryDrawer = () => {
  const [showFilingHistory, setSearchParams] = useQueryParam('showFilingHistory');

  const currentMonthEnd = getFormattedDate(endOfMonth(new Date()), 'yyyy-MM-dd');
  const [filingHistoryFilters, setFilingHistoryFilters] = useState<FilingHistoryFiltersType>({
    status__in: FilingStatus.FILED,
    date_filed__lte: currentMonthEnd,
    order_by: ['start_date', 'end_date', 'amount', 'date_filed'],
    start_date: undefined,
    end_date: undefined,
    state_code: undefined,
    country_code: undefined,
  });

  const updateFilters = (newValue: Partial<FilingHistoryFiltersType>) => {
    setFilingHistoryFilters(prev => ({ ...prev, ...newValue }));
  };

  const updateOrder = (newValue: string[]) => {
    setFilingHistoryFilters(prev => ({ ...prev, order_by: newValue }));
  };
  const onClose = () => {
    setSearchParams({});
  };

  return (
    <DrawerRoot open={showFilingHistory} size="xl" placement="end" onInteractOutside={onClose}>
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader>
          <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} width="100%">
            <HStack gap={2}>
              <IconButton rounded={'sm'} size="xs" variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
                <ArrowLeft />
              </IconButton>
              <Text fontSize={'xl'} fontWeight={500}>
                History
              </Text>
            </HStack>
            <Box flex={1} minWidth="20px" />
            <FilterButton
              popoverContentProps={{ portalled: false }}
              tableFilters={filingHistoryFilters}
              updateFilters={updateFilters}
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <FilingsHistoryTable tableFilters={filingHistoryFilters} updateOrder={updateOrder} />
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};

export default FilingHistoryDrawer;
